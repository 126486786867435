import type { Professional } from '~/types/__generated__'

export const useRegisterProfessionalStore = defineStore(
  'registerProfessional',
  () => {
    const referral = ref('')
    const step = ref(1)
    const generalData = ref({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password1: '',
      password2: '',
    })
    const contactData = ref({
      address: '',
      postalCode: '',
      birthDate: '',
      city: '',
      country: '',
      agreedToTerms: false,
    })
    const phoneNumberIsValid = ref(false)
    const otpId = ref(null)
    const aboutData = ref({
      image: '',
      socials: [
        {
          type: 'linkedin',
          url: '',
        },
      ],
      description: '',
      hobbies: [],
    })
    const searchData = ref({
      status: '',
      maximumRadiusKm: 100,
      currentJobTitle: '',
      searchSimilar: true,
      tags: [],
      filterTags: [],
    })
    const experienceData = ref({
      experiences: [],
    })

    const { updateUser } = useApiUser()
    const { updateProfessional } = useApiProfessional()
    const { register } = useStrapiAuth()
    const { executeRecaptcha } = useNuxtApp()?.$recaptcha

    async function updateAbout(user) {
      await updateUser(user.id, {
        data: {
          contact: {
            ...user.contact,
            image: aboutData.value.image,
            socials: aboutData.value.socials,
          },
        },
      })

      await updateProfessional(user.professional.id, {
        data: {
          about: {
            description: aboutData.value.description,
            hobbies: aboutData.value.hobbies,
          },
        },
      })
    }

    async function updateSearch(user: Professional) {
      await updateProfessional(user.professional.id, {
        data: {
          status: searchData.value.status,
          maximumRadiusKm: searchData.value.maximumRadiusKm,
          currentJobTitle: searchData.value.currentJobTitle,
          searchSimilar: searchData.value.searchSimilar,
        },
      })
    }

    async function updateTags(user: Professional) {
      await updateProfessional(user.professional.id, {
        data: {
          tags: searchData.value.tags,
          filterTags: searchData.value.filterTags,
        },
      })
    }

    async function createProfessional() {
      const address = {
        address: contactData.value.address,
        zip_code: contactData.value.postalCode,
        city: contactData.value.city,
        country: contactData.value.country,
      }

      const form = {
        recaptchaToken: await executeRecaptcha('register'),
        referral: referral.value,
        type: 'professional',
        contact: {
          first_name: generalData.value.firstName,
          last_name: generalData.value.lastName,
          phone_number: generalData.value.phone,
          date_of_birth: contactData.value.birthDate,
        },
        address,
        username: generalData.value.email,
        email: generalData.value.email,
        password: generalData.value.password1,
        passwordConfirm: generalData.value.password2,
        professionalDetails: {
          address,
        },
        preferences: {
          dontReceiveCronJobEmails: false,
        },
        otpId: otpId.value,
      }

      try {
        // @ts-ignore
        await register(form)
        navigateTo('/onboarding/professional')
      } catch (e) {
        return e
      }
    }

    async function updateExperiences(user: Professional) {
      await updateProfessional(user.professional.id, {
        data: {
          experiences: experienceData.value.experiences,
        },
      })
    }

    return {
      step,
      generalData,
      contactData,
      otpId,
      aboutData,
      searchData,
      experienceData,
      phoneNumberIsValid,
      referral,
      updateAbout,
      updateSearch,
      updateTags,
      createProfessional,
      updateExperiences,
    }
  },
)
